<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title
                        style="border-bottom: 1px solid #404040"
                        class="pt-2 pb-2"
                    >
                        <div
                            class="text-left"
                            style="
                                margin-left: 0;
                                width: 50%;
                                font-size: 16px;
                                font-weight: normal;
                            "
                        >
                            SEARCH FILTER
                        </div>
                    </v-card-title>

                    <v-row class="pl-4 pt-2">
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete
                                label="Filter Book"
                                v-model="selectedBook"
                                :items="bookData"
                                item-text="book_name"
                                item-value="book_name"
                                auto-select-first
                                chips
                                clearable
                                deletable-chips
                                multiple
                                small-chips
                                hide-details
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete
                                v-model="selectedCmd"
                                :items="filterCmdLists"
                                item-text="name"
                                item-value="cmd"
                                label="Filter Order Type"
                                auto-select-first
                                chips
                                clearable
                                deletable-chips
                                multiple
                                small-chips
                                hide-details
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <div
                                class="text-left"
                                style="margin-left: 20px; margin-top: 20px"
                            >
                                <div>
                                    <v-btn
                                        text
                                        icon
                                        color="blue lighten-2"
                                        @click="showMore()"
                                        v-if="!showMoreStatus"
                                    >
                                        More<v-icon large
                                            >mdi-chevron-down</v-icon
                                        >
                                    </v-btn>
                                    <v-btn
                                        text
                                        icon
                                        color="blue lighten-2"
                                        @click="showMore()"
                                        v-if="showMoreStatus"
                                    >
                                        Fewer<v-icon large
                                            >mdi-chevron-up</v-icon
                                        >
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="pl-4" v-if="showMoreStatus">
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete
                                v-model="selectedReason"
                                :items="filterReasonLists"
                                item-text="name"
                                item-value="value"
                                hide-details
                                label="Filter Reason"
                                auto-select-first
                                chips
                                clearable
                                deletable-chips
                                multiple
                                small-chips
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-text-field
                                label="Enter Symbol to Search"
                                v-model="selectedSymbol"
                                hide-details
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-switch
                                hide-details
                                style="width: 100%"
                                v-model="selectedIsOpen"
                                :label="`Is Opening Trade?`"
                            ></v-switch>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-datetime-picker
                                label="Filter Open Time (Start)"
                                v-model="selectedOpenTimeStart"
                                hide-details
                            ></v-datetime-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-datetime-picker
                                label="Filter Open Time (End)"
                                v-model="selectedOpenTimeEnd"
                                hide-details
                            ></v-datetime-picker>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="2"
                            xl="2"
                            v-if="toggleCloseFilter"
                        >
                            <v-datetime-picker
                                label="Filter Close Time (Start)"
                                v-model="selectedCloseTimeStart"
                                hide-details
                            >
                            </v-datetime-picker>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="2"
                            xl="2"
                            v-if="toggleCloseFilter"
                        >
                            <v-datetime-picker
                                label="Filter Close Time (End)"
                                v-model="selectedCloseTimeEnd"
                            ></v-datetime-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete
                                label="Order By"
                                v-model="selectedOrderBy"
                                :items="filterOrderBy"
                                auto-select-first
                                hide-details
                                chips
                                clearable
                                deletable-chips
                                small-chips
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                            <v-switch
                                style="width: 100%"
                                v-model="isAsc"
                                hide-details
                                :label="`Is Ascending Order?`"
                            ></v-switch>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete
                                label="Show Result"
                                v-model="selectedLimit"
                                :items="filterShowResult"
                                auto-select-first
                                hide-details
                                chips
                                clearable
                                deletable-chips
                                small-chips
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field
                                label="Filter Login (Comma to Seprate Multi Login, Eg: 100000000,200000000,30000000)"
                                v-model="selectedLogin"
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="pa-4 pt-0">
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <v-btn
                                small
                                v-on:click="fetchTradeRecord()"
                                :loading="btnLoading"
                                color="primary"
                                >SEARCH</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title
                        style="border-bottom: 1px solid #404040"
                        class="pt-2 pb-2"
                    >
                        <div
                            class="text-left"
                            style="
                                margin-left: 0;
                                width: 50%;
                                font-size: 16px;
                                font-weight: normal;
                            "
                        >
                            RESULT
                        </div>
                        <div
                            class="text-right"
                            style="margin-right: 0; width: 50%"
                        >
                            <download-excel :data="tradesList" :name="'Search'">
                                <v-icon color="green" dark dense>
                                    mdi-microsoft-excel
                                </v-icon>
                            </download-excel>
                        </div>
                    </v-card-title>

                    <v-data-table
                        dense
                        :search="search"
                        :headers="headers"
                        :loading="loading"
                        :items="filteredDataSource"
                        class="elevation-1"
                        :items-per-page="15"
                        :header-props="{ sortIcon: null }"
                    >
                        <template v-slot:header.login="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="setFocus(`loginName`)"
                                    >
                                        <v-icon
                                            small
                                            :color="loginName ? 'primary' : ''"
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 280px;
                                    "
                                >
                                    <v-text-field
                                        v-model="loginName"
                                        class="px-4"
                                        type="text"
                                        ref="loginName"
                                        label="Enter the search term"
                                        style="width: 100%"
                                    ></v-text-field>
                                    <v-btn
                                        @click="loginName = ''"
                                        small
                                        text
                                        color="primary"
                                        class="ml-2 mb-2"
                                    >
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.ticket="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="setFocus(`ticketName`)"
                                    >
                                        <v-icon
                                            small
                                            :color="ticketName ? 'primary' : ''"
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 280px;
                                    "
                                >
                                    <v-text-field
                                        v-model="ticketName"
                                        class="px-4"
                                        type="text"
                                        ref="ticketName"
                                        label="Enter the search term"
                                        style="width: 100%"
                                    ></v-text-field>
                                    <v-btn
                                        @click="ticketName = ''"
                                        small
                                        text
                                        color="primary"
                                        class="ml-2 mb-2"
                                    >
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.symbol="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="setFocus(`symbolName`)"
                                    >
                                        <v-icon
                                            small
                                            :color="symbolName ? 'primary' : ''"
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 280px;
                                    "
                                >
                                    <v-text-field
                                        v-model="symbolName"
                                        class="px-4"
                                        type="text"
                                        ref="symbolName"
                                        label="Enter the search term"
                                        style="width: 100%"
                                    ></v-text-field>
                                    <v-btn
                                        @click="symbolName = ''"
                                        small
                                        text
                                        color="primary"
                                        class="ml-2 mb-2"
                                    >
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.cmd="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon
                                            small
                                            :color="cmdName ? 'primary' : ''"
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 150px;
                                    "
                                >
                                    <v-container class="pb-0 pt-1" fluid>
                                        <v-radio-group
                                            v-model="cmdName"
                                            class="mt-0 mb-0 pb-0"
                                        >
                                            <v-radio
                                                label="Buy"
                                                value="Buy"
                                            ></v-radio>
                                            <v-radio
                                                label="Sell"
                                                value="Sell"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-container>
                                    <v-btn
                                        @click="cmdName = ''"
                                        small
                                        text
                                        color="primary"
                                        class="ml-2 mb-2"
                                    >
                                        Clean</v-btn
                                    >
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.comment="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="setFocus(`commentName`)"
                                    >
                                        <v-icon
                                            small
                                            :color="
                                                commentName ? 'primary' : ''
                                            "
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 280px;
                                    "
                                >
                                    <v-text-field
                                        v-model="commentName"
                                        class="px-4"
                                        type="text"
                                        ref="commentName"
                                        label="Enter the search term"
                                        style="width: 100%"
                                    ></v-text-field>
                                    <v-btn
                                        @click="commentName = ''"
                                        small
                                        text
                                        color="primary"
                                        class="ml-2 mb-2"
                                    >
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.reason="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon
                                            small
                                            :color="reasonName ? 'primary' : ''"
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 150px;
                                    "
                                >
                                    <v-container class="pb-0 pt-1" fluid>
                                        <v-radio-group
                                            v-model="reasonName"
                                            class="mt-0 mb-0 pb-0"
                                        >
                                            <v-radio
                                                label="CLIENT"
                                                value="CLIENT"
                                            ></v-radio>
                                            <v-radio
                                                label="EXPERT"
                                                value="EXPERT"
                                            ></v-radio>
                                            <v-radio
                                                label="DEALER"
                                                value="DEALER"
                                            ></v-radio>
                                            <v-radio
                                                label="SIGNAL"
                                                value="SIGNAL"
                                            ></v-radio>
                                            <v-radio
                                                label="GATEWAY"
                                                value="GATEWAY"
                                            ></v-radio>
                                            <v-radio
                                                label="MOBILE"
                                                value="MOBILE"
                                            ></v-radio>
                                            <v-radio
                                                label="WEB"
                                                value="WEB"
                                            ></v-radio>
                                            <v-radio
                                                label="API"
                                                value="API"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-container>
                                    <v-btn
                                        @click="reasonName = ''"
                                        small
                                        text
                                        color="primary"
                                        class="ml-2 mb-2"
                                    >
                                        Clean</v-btn
                                    >
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.volume="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="setFocus(`volumeName`)"
                                    >
                                        <v-icon
                                            small
                                            :color="volumeName ? 'primary' : ''"
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 280px;
                                    "
                                >
                                    <v-text-field
                                        v-model="volumeName"
                                        class="px-4"
                                        type="text"
                                        ref="volumeName"
                                        label="Volume >="
                                        style="width: 100%"
                                    ></v-text-field>
                                    <v-btn
                                        @click="volumeName = ''"
                                        small
                                        text
                                        color="primary"
                                        class="ml-2 mb-2"
                                    >
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.open_price="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="setFocus(`openPriceName`)"
                                    >
                                        <v-icon
                                            small
                                            :color="
                                                openPriceName ? 'primary' : ''
                                            "
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 280px;
                                    "
                                >
                                    <v-text-field
                                        v-model="openPriceName"
                                        class="px-4"
                                        type="text"
                                        ref="openPriceName"
                                        label="Open Price >="
                                        style="width: 100%"
                                    ></v-text-field>
                                    <v-btn
                                        @click="openPriceName = ''"
                                        small
                                        text
                                        color="primary"
                                        class="ml-2 mb-2"
                                    >
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>

                        <template v-slot:header.open_time="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon
                                            small
                                            :color="
                                                openTimeRange[0] !=
                                                    openTimeMin ||
                                                openTimeRange[1] != openTimeMax
                                                    ? 'primary'
                                                    : ''
                                            "
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 500px;
                                    "
                                >
                                    <v-card flat>
                                        <!-- <v-subheader>Min and max range slider</v-subheader> -->
                                        <v-card-text>
                                            <v-row>
                                                <v-col class="px-4">
                                                    <v-range-slider
                                                        v-model="openTimeRange"
                                                        :max="openTimeMax"
                                                        :min="openTimeMin"
                                                        hide-details
                                                        class="align-center"
                                                    >
                                                        <template
                                                            v-slot:prepend
                                                        >
                                                            <v-text-field
                                                                :value="
                                                                    timehelper(
                                                                        openTimeRange[0]
                                                                    )
                                                                "
                                                                class="mt-0 pt-0"
                                                                hide-details
                                                                single-line
                                                                disabled
                                                                dense
                                                                style="
                                                                    width: 147px;
                                                                "
                                                                @change="
                                                                    $set(
                                                                        openTimeRange,
                                                                        0,
                                                                        $event
                                                                    )
                                                                "
                                                            ></v-text-field>
                                                        </template>
                                                        <template v-slot:append>
                                                            <v-text-field
                                                                :value="
                                                                    timehelper(
                                                                        openTimeRange[1]
                                                                    )
                                                                "
                                                                class="mt-0 pt-0"
                                                                hide-details
                                                                single-line
                                                                disabled
                                                                dense
                                                                style="
                                                                    width: 147px;
                                                                "
                                                                @change="
                                                                    $set(
                                                                        openTimeRange,
                                                                        1,
                                                                        $event
                                                                    )
                                                                "
                                                            ></v-text-field>
                                                        </template>
                                                    </v-range-slider>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-btn
                                                    @click="
                                                        openTimeRange = [
                                                            openTimeMin,
                                                            openTimeMax,
                                                        ]
                                                    "
                                                    small
                                                    text
                                                    color="primary"
                                                    class="ml-2 mb-2"
                                                >
                                                    Reset
                                                </v-btn>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </v-menu>
                        </template>

                        <template v-slot:header.close_time="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon
                                            small
                                            :color="
                                                closeTimeRange[0] !=
                                                    closeTimeMin ||
                                                closeTimeRange[1] !=
                                                    closeTimeMax
                                                    ? 'primary'
                                                    : ''
                                            "
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 500px;
                                    "
                                >
                                    <v-card flat>
                                        <!-- <v-subheader>Min and max range slider</v-subheader> -->
                                        <v-card-text>
                                            <v-row>
                                                <v-col class="px-4">
                                                    <v-range-slider
                                                        v-model="closeTimeRange"
                                                        :max="closeTimeMax"
                                                        :min="closeTimeMin"
                                                        hide-details
                                                        class="align-center"
                                                    >
                                                        <template
                                                            v-slot:prepend
                                                        >
                                                            <v-text-field
                                                                :value="
                                                                    timehelper(
                                                                        closeTimeRange[0]
                                                                    )
                                                                "
                                                                class="mt-0 pt-0"
                                                                hide-details
                                                                single-line
                                                                disabled
                                                                dense
                                                                style="
                                                                    width: 147px;
                                                                "
                                                                @change="
                                                                    $set(
                                                                        closeTimeRange,
                                                                        0,
                                                                        $event
                                                                    )
                                                                "
                                                            >
                                                            </v-text-field>
                                                        </template>
                                                        <template v-slot:append>
                                                            <v-text-field
                                                                :value="
                                                                    timehelper(
                                                                        closeTimeRange[1]
                                                                    )
                                                                "
                                                                class="mt-0 pt-0"
                                                                hide-details
                                                                single-line
                                                                disabled
                                                                dense
                                                                style="
                                                                    width: 147px;
                                                                "
                                                                @change="
                                                                    $set(
                                                                        closeTimeRange,
                                                                        1,
                                                                        $event
                                                                    )
                                                                "
                                                            >
                                                            </v-text-field>
                                                        </template>
                                                    </v-range-slider>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-btn
                                                    @click="
                                                        closeTimeRange = [
                                                            closeTimeMin,
                                                            closeTimeMax,
                                                        ]
                                                    "
                                                    small
                                                    text
                                                    color="primary"
                                                    class="ml-2 mb-2"
                                                >
                                                    Reset
                                                </v-btn>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </v-menu>
                        </template>

                        <template v-slot:header.close_price="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="setFocus(`closePriceName`)"
                                    >
                                        <v-icon
                                            small
                                            :color="
                                                closePriceName ? 'primary' : ''
                                            "
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 280px;
                                    "
                                >
                                    <v-text-field
                                        v-model="closePriceName"
                                        class="px-4"
                                        type="text"
                                        ref="closePriceName"
                                        label="Close Price >="
                                        style="width: 100%"
                                    ></v-text-field>
                                    <v-btn
                                        @click="closePriceName = ''"
                                        small
                                        text
                                        color="primary"
                                        class="ml-2 mb-2"
                                    >
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.profit="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="setFocus(`profitName`)"
                                    >
                                        <v-icon
                                            small
                                            :color="profitName ? 'primary' : ''"
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 280px;
                                    "
                                >
                                    <v-text-field
                                        v-model="profitName"
                                        class="px-4"
                                        type="text"
                                        ref="profitName"
                                        label="Profit >="
                                        style="width: 100%"
                                    ></v-text-field>
                                    <v-btn
                                        @click="profitName = ''"
                                        small
                                        text
                                        color="primary"
                                        class="ml-2 mb-2"
                                    >
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.sl="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="setFocus(`slName`)"
                                    >
                                        <v-icon
                                            small
                                            :color="slName ? 'primary' : ''"
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 280px;
                                    "
                                >
                                    <v-text-field
                                        v-model="slName"
                                        class="px-4"
                                        type="text"
                                        ref="slName"
                                        label="SL >="
                                        style="width: 100%"
                                    ></v-text-field>
                                    <v-btn
                                        @click="slName = ''"
                                        small
                                        text
                                        color="primary"
                                        class="ml-2 mb-2"
                                    >
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.tp="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="setFocus(`tpName`)"
                                    >
                                        <v-icon
                                            small
                                            :color="tpName ? 'primary' : ''"
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 280px;
                                    "
                                >
                                    <v-text-field
                                        v-model="tpName"
                                        class="px-4"
                                        type="text"
                                        ref="tpName"
                                        label="TP >="
                                        style="width: 100%"
                                    ></v-text-field>
                                    <v-btn
                                        @click="tpName = ''"
                                        small
                                        text
                                        color="primary"
                                        class="ml-2 mb-2"
                                    >
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.swaps="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="setFocus(`swapsName`)"
                                    >
                                        <v-icon
                                            small
                                            :color="swapsName ? 'primary' : ''"
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 280px;
                                    "
                                >
                                    <v-text-field
                                        v-model="swapsName"
                                        class="px-4"
                                        type="text"
                                        ref="swapsName"
                                        label="Swaps <="
                                        style="width: 100%"
                                    ></v-text-field>
                                    <v-btn
                                        @click="swapsName = ''"
                                        small
                                        text
                                        color="primary"
                                        class="ml-2 mb-2"
                                    >
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.commission="{ header }">
                            {{ header.text }}
                            <v-menu
                                offset-y
                                :close-on-content-click="false"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="setFocus(`commissionName`)"
                                    >
                                        <v-icon
                                            small
                                            :color="
                                                commissionName ? 'primary' : ''
                                            "
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div
                                    style="
                                        background-color: #1e1e1e;
                                        width: 280px;
                                    "
                                >
                                    <v-text-field
                                        v-model="commissionName"
                                        class="px-4"
                                        type="text"
                                        ref="commissionName"
                                        label="Commission <="
                                        style="width: 100%"
                                    ></v-text-field>
                                    <v-btn
                                        @click="commissionName = ''"
                                        small
                                        text
                                        color="primary"
                                        class="ml-2 mb-2"
                                    >
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:item.open_price="{ item }">
                            {{
                                decimalPlaceCorrection(
                                    item.symbol,
                                    item.open_price
                                )
                            }} </template
                        ><template v-slot:item.close_price="{ item }">
                            {{
                                decimalPlaceCorrection(
                                    item.symbol,
                                    item.close_price
                                )
                            }} </template
                        ><template v-slot:item.sl="{ item }">
                            {{ decimalPlaceCorrection(item.symbol, item.sl) }}
                        </template>
                        <template v-slot:item.tp="{ item }">
                            {{
                                decimalPlaceCorrection(item.symbol, item.tp)
                            }} </template
                        ><template v-slot:item.profit="{ item }">
                            {{ numberWithCommas(item.profit.toFixed(2)) }}
                        </template>
                        <template v-slot:item.comment="{ item }">
                            <div v-if="item.comment.length <= 9">
                                {{ item.comment }}
                            </div>
                            <v-tooltip top v-else>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        {{ item.comment.slice(0, 9)
                                        }}<v-icon small
                                            >mdi-dots-horizontal</v-icon
                                        >
                                    </div>
                                </template>
                                <span>{{ item.comment }}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { snackbar } from "@components/mixins/snackbar";
import { permission } from "@components/mixins/permission";
import { helper } from "@components/mixins/helper";
import { mapState, mapActions } from "vuex";

export default {
    mixins: [permission, snackbar, helper],
    data() {
        return {
            selectedBrokerId: 1,
            selectedBook: [],
            selectedCmd: [0, 1],
            selectedReason: [],
            selectedSymbol: "",
            selectedIsOpen: false,
            selectedOpenTimeStart: null,
            selectedOpenTimeEnd: null,
            selectedCloseTimeStart: null,
            selectedCloseTimeEnd: null,
            selectedOrderBy: "open_time",
            isAsc: false,
            selectedLimit: 100,
            selectedLogin: "",
            showMoreStatus: false,

            ticketName: "",
            loginName: "",
            symbolName: "",
            cmdName: "",
            commentName: "",
            reasonName: "",
            volumeName: "",
            openPriceName: "",
            closePriceName: "",
            profitName: "",
            slName: "",
            tpName: "",
            commissionName: "",
            swapsName: "",
            toggleCloseFilter: true,
            filterBookLists: [],
            search: "",
        };
    },
    computed: {
        ...mapState("RawHistory", [
            "filterOrderBy",
            "filterCmdLists",
            "filterReasonLists",
            "csvHeader",
            "filterShowResult",
            "headers",
            "loading",
            "btnLoading",
            "tradesList",
            "openTimeMin",
            "openTimeMax",
            "openTimeRange",
            "openTimePeriod",
            "closeTimeMin",
            "closeTimeMax",
            "closeTimeRange",
            "closeTimePeriod",
        ]),
        ...mapState("Home", ["symbolDP"]),
        ...mapState("SettingsBooks", ["bookData"]),
        filteredDataSource() {
            const conditions = [];
            if (this.loginName) {
                conditions.push(this.filterLoginName);
            }
            if (this.commentName) {
                conditions.push(this.filterCommentName);
            }
            if (this.symbolName) {
                conditions.push(this.filterSymbolName);
            }
            if (this.reasonName) {
                conditions.push(this.filterReasonName);
            }
            if (this.ticketName) {
                conditions.push(this.filterTicketName);
            }
            if (this.cmdName) {
                conditions.push(this.filterCmdName);
            }
            if (
                this.openTimeRange[0] != this.openTimeMin ||
                this.openTimeRange[1] != this.openTimeMax
            ) {
                conditions.push(this.filterOpenTimeRange);
            }
            if (
                this.closeTimeRange[0] != this.closeTimeMin ||
                this.closeTimeRange[1] != this.closeTimeMax
            ) {
                conditions.push(this.filterCloseTimeRange);
            }
            if (this.volumeName) {
                conditions.push(this.filterVolumeName);
            }
            if (this.openPriceName) {
                conditions.push(this.filterOpenPriceName);
            }
            if (this.closePriceName) {
                conditions.push(this.filterClosePriceName);
            }
            if (this.profitName) {
                conditions.push(this.filterProfitName);
            }
            if (this.slName) {
                conditions.push(this.filterSlName);
            }
            if (this.tpName) {
                conditions.push(this.filterTpName);
            }
            if (this.commissionName) {
                conditions.push(this.filterCommissionName);
            }
            if (this.swapsName) {
                conditions.push(this.filterSwapsName);
            }

            if (conditions.length > 0) {
                return this.tradesList.filter((item) => {
                    return conditions.every((condition) => {
                        return condition(item);
                    });
                });
            }
            return this.tradesList;
        },
    },
    watch: {
        /**
         * On book data loaded
         * @param   {[type]}  nv  [nv description]
         * @return  {[type]}      [return description]
         */
        bookData(nv) {
            if (nv.length !== 0) this.selectedBook = [nv[0].book_name];
        },
        selectedIsOpen(nv, ov) {
            if (nv == true) {
                this.selectedCloseTimeStart = null;
                this.selectedCloseTimeEnd = null;
                this.toggleCloseFilter = false;
            } else {
                this.toggleCloseFilter = true;
            }
        },
    },
    methods: {
        ...mapActions("RawHistory", ["getRawHistoryAction"]),
        ...mapActions("SettingsBooks", ["getBooksAction"]),
        showMore() {
            this.showMoreStatus = !this.showMoreStatus;
        },
        /**
         * Search order
         * @return  {[type]}  [return description]
         */
        fetchTradeRecord() {
            const self = this;
            const finalFilterSymbol = [];

            const params = {
                selectedLogin: self.selectedLogin,
                selectedCmd: self.selectedCmd,
                selectedReason: self.selectedReason,
                selectedBook: self.selectedBook,
                selectedSymbol: self.selectedSymbol,
                selectedOpenTimeStart: self.selectedOpenTimeStart,
                selectedOpenTimeEnd: self.selectedOpenTimeEnd,
                selectedCloseTimeStart: self.selectedCloseTimeStart,
                selectedCloseTimeEnd: self.selectedCloseTimeEnd,
                selectedIsOpen: self.selectedIsOpen,
                selectedLimit: self.selectedLimit,
                selectedOrderBy: self.selectedOrderBy,
                isAsc: self.isAsc,
            };
            console.log(params);
            self.getRawHistoryAction(params);
        },
        timehelper(time) {
            const timeString = new Date(time).toLocaleString("en-GB");
            const newTime =
                timeString.substr(6, 4) +
                "-" +
                timeString.substr(3, 2) +
                "-" +
                timeString.substr(0, 2) +
                " " +
                timeString.substr(12, 8);
            return newTime;
        },

        setFocus(item) {
            setTimeout(() => {
                this.$refs[`${item}`].focus();
            }, 200);
        },
        filterLoginName(item) {
            console.log(item.login.toString().includes(this.loginName));
            return item.login.toString().includes(this.loginName);
        },
        filterTicketName(item) {
            return item.ticket.toString().includes(this.ticketName);
        },
        filterSymbolName(item) {
            return item.symbol
                .toLowerCase()
                .includes(this.symbolName.toLowerCase());
        },
        filterCommentName(item) {
            return item.comment
                .toLowerCase()
                .includes(this.commentName.toLowerCase());
        },
        filterCmdName(item) {
            return item.cmd == this.cmdName;
        },
        filterReasonName(item) {
            return item.reason == this.reasonName;
        },
        filterVolumeName(item) {
            return item.volume >= this.volumeName;
        },
        filterOpenPriceName(item) {
            return item.open_price >= this.openPriceName;
        },
        filterClosePriceName(item) {
            return item.close_price >= this.closePriceName;
        },
        filterProfitName(item) {
            return item.profit >= this.profitName;
        },
        filterSlName(item) {
            return item.sl >= this.slName;
        },
        filterTpName(item) {
            return item.tp >= this.tpName;
        },
        filterSwapsName(item) {
            return item.swaps <= this.swapsName;
        },
        filterCommissionName(item) {
            return item.commission <= this.commissionName;
        },

        filterOpenTimeRange(item) {
            return !!(
                Date.parse(item.open_time) >= this.openTimeRange[0] &&
                Date.parse(item.open_time) <= this.openTimeRange[1]
            );
        },
        filterCloseTimeRange(item) {
            return !!(
                Date.parse(item.close_time) >= this.closeTimeRange[0] &&
                Date.parse(item.close_time) <= this.closeTimeRange[1]
            );
        },
        decimalPlaceCorrection(symbol, symbolPrice) {
            if (Number.isInteger(symbolPrice)) {
                return symbolPrice;
            } else {
                if (
                    symbolPrice.toString().split(".")[1].length <=
                    this.symbolDP[symbol]
                ) {
                    return symbolPrice;
                }
                return symbolPrice.toFixed(this.symbolDP[symbol]);
            }
        },
    },
    mounted() {
        this.getBooksAction();
        // this.filterBookLists = this.getBookListByBrokerId(
        //     this.selectedBrokerId
        // );
    },
};
</script>
<style>
.v-data-table-header__icon {
    margin-left: -2px;
}
table > thead > tr > th:nth-child(n) > button {
    margin-left: -13px;
    margin-right: -10px;
    width: 10%;
}
.v-text-field__details {
    display: none;
}
</style>
