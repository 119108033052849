<template>
    <div class="home">
        <v-card>
            <v-container fluid>
                <v-row>
                    <v-col cols="12" style="padding: 0px 12px">
                        <v-tabs>
                            <v-tab v-on:click="changeView(1)"
                                >Raw History</v-tab
                            >
                            <v-tab v-on:click="changeView(2)"
                                >Transaction</v-tab
                            >
                            <v-tab v-on:click="changeView(3)"
                                >Profit & Loss</v-tab
                            >
                            <v-tab v-on:click="changeView(4)">Volume</v-tab>
                            <!-- <v-tab v-on:click="changeView(5)">Tick Spread Daily</v-tab> -->
                        </v-tabs>
                    </v-col>
                </v-row>

                <OrderSearch v-if="selectedModule == 1" />
                <TransactionSearch v-if="selectedModule == 2" />
                <ProfitLossSearch v-if="selectedModule == 3" />
                <VolumeSearch v-if="selectedModule == 4" />
                <TickSpreadSearch v-if="selectedModule == 5" />
            </v-container>
        </v-card>
    </div>
</template>
<script>
import VolumeSearch from "./component/ComponentVolumeSearch";
import OrderSearch from "./component/ComponentOrderSearch";
import TransactionSearch from "./component/ComponentTransactionSearch";
import ProfitLossSearch from "./component/ComponentProfitLossSearch";
import TickSpreadSearch from "./component/ComponentTickSpread";

import { permission } from "@components/mixins/permission";
import dayjs from "dayjs";

import Breadcrumbs from "@components/Breadcrumbs";
dayjs.locale("en-au");

export default {
    components: {
        Breadcrumbs,
        OrderSearch,
        TransactionSearch,
        ProfitLossSearch,
        TickSpreadSearch,
        VolumeSearch,
    },
    mixins: [permission],
    data() {
        return {
            selectedModule: 1,
            loadRawHistory: 0,
            loadTransaction: 0,
            loadProfitLoss: 0,
            loadVolume: 0,
        };
    },
    methods: {
        changeView(key) {
            this.selectedModule = key;
        },
    },
    mounted() {},
};
</script>
