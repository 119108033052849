<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title
                        style="border-bottom: 1px solid #404040"
                        class="pt-2 pb-2"
                    >
                        <div
                            class="text-left"
                            style="
                                margin-left: 0;
                                width: 50%;
                                font-size: 16px;
                                font-weight: normal;
                            "
                        >
                            SEARCH FILTER
                        </div>
                    </v-card-title>

                    <v-row class="pl-4 pt-2">
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-text-field
                                label="Enter Symbol to Search"
                                v-model="selectedSymbol"
                                hide-details="auto"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-text-field
                                label="Enter Date to Search"
                                v-model="selectedRecordDate"
                                hide-details="auto"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="pa-4 pt-0">
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <v-btn
                                small
                                v-on:click="getTickSpread()"
                                :loading="btnLoading"
                                color="primary"
                                >SEARCH</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title
                        style="border-bottom: 1px solid #404040"
                        class="pt-2 pb-2"
                    >
                        <div
                            class="text-left"
                            style="
                                margin-left: 0;
                                width: 50%;
                                font-size: 16px;
                                font-weight: normal;
                            "
                        >
                            RESULT
                        </div>
                        <div
                            class="text-right"
                            style="margin-right: 0; width: 50%"
                        >
                            <download-excel
                                :data="tickSpreadData"
                                :name="'Search'"
                            >
                                <v-icon color="green" dark dense>
                                    mdi-microsoft-excel
                                </v-icon>
                            </download-excel>
                        </div>
                    </v-card-title>

                    <v-data-table
                        dense
                        :search="search"
                        :headers="tickSpreadHeader"
                        :loading="loading"
                        :items="tickSpreadData"
                        class="elevation-1"
                        :items-per-page="15"
                        :header-props="{ sortIcon: null }"
                    >
                        <template v-slot:item.count="{ item }">
                            {{ numberWithCommas(item.count.toFixed(2)) }}
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { snackbar } from "@components/mixins/snackbar";
import { permission } from "@components/mixins/permission";
import { helper } from "@components/mixins/helper";
import { mapState, mapActions } from "vuex";

export default {
    mixins: [permission, snackbar, helper],
    data() {
        return {
            selectedSymbol: "XAUUSD-STD",
            selectedRecordDate: "2022-08-03",
            search: "",
        };
    },
    computed: {
        ...mapState("TickSpread", [
            "sessionLists",
            "loading",
            "btnLoading",
            "tickSpreadHeader",
            "tickSpreadData",
        ]),
    },
    methods: {
        ...mapActions("TickSpread", ["getTickSpreadActions"]),
        ...mapActions("SettingsBooks", ["getBooksAction"]),

        getTickSpread() {
            let params = {
                symbol: this.selectedSymbol,
                record_date: this.selectedRecordDate,
            };
            this.getTickSpreadActions(params);
        },
    },
    mounted() {},
};
</script>
<style>
.v-data-table-header__icon {
    margin-left: -2px;
}
table > thead > tr > th:nth-child(n) > button {
    margin-left: -13px;
    margin-right: -10px;
    width: 10%;
}
</style>
